<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        fill="currentColor"
        d="M 29.5,7.5 C 36.5605,11.7222 42.5605,17.2222 47.5,24C 45.8038,26.9823 43.1371,28.3156 39.5,28C 39.9574,28.414 40.2907,28.914 40.5,29.5C 39.3523,31.1487 38.019,32.6487 36.5,34C 41.5,39 46.5,44 51.5,49C 50.6667,49.8333 49.8333,50.6667 49,51.5C 43.9853,46.3182 38.8186,41.3182 33.5,36.5C 31.8333,37.5 30.5,38.8333 29.5,40.5C 26.2062,38.9931 25.5396,39.8265 27.5,43C 26.1177,46.6812 23.7844,47.3478 20.5,45C 15.9657,40.8006 11.799,36.3006 8,31.5C 7.81457,27.028 9.81457,25.6946 14,27.5C 14.4922,26.451 14.3256,25.451 13.5,24.5C 17.5,21.1667 21.1667,17.5 24.5,13.5C 27.1667,14.8333 27.8333,14.1667 26.5,11.5C 27.1716,9.8405 28.1716,8.50716 29.5,7.5 Z"
      />
    </g>
  </svg>
</template>
